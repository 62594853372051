import { useEffect } from "react";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useAppParams } from "@router/router-helper";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { companyMembersSelector } from "@store/members/members-selector";
import { fetchCompanyMembers } from "@store/members/members-slice-thunk";
import { MemberTypes } from "@custom-types/member-types";
import { fetchTeams } from "@store/teams/teams-slice-thunk";
import { isBetaTestingEnabledOrNotProdSelector } from "@store/app/app-selector";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";

/** Returns all the members in the company */
export function useCompanyMembers(): MemberTypes[] {
  const coreApiClient = useCoreApiClient();
  const { companyId } = useAppParams();
  const dispatch = useAppDispatch();
  const companyMembers = useAppSelector(companyMembersSelector);
  const isBetaTestingEnabled = useAppSelector(
    isBetaTestingEnabledOrNotProdSelector
  );

  // TODO: https://faro01.atlassian.net/browse/ST-2758 remove the role for the fetch teams
  const { canViewCompanyMemberProfile } = useHasUserValidRoleCompanyLevel();

  // Fetch all the members in the company
  useEffect(() => {
    async function fetchCompanyMembersViaStore(): Promise<void> {
      if (companyId) {
        await dispatch(
          fetchCompanyMembers({
            coreApiClient,
            companyId,
          })
        );
      }

      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      isBetaTestingEnabled &&
        canViewCompanyMemberProfile &&
        (await dispatch(fetchTeams({ coreApiClient })));
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    fetchCompanyMembersViaStore();
  }, [
    canViewCompanyMemberProfile,
    companyId,
    coreApiClient,
    dispatch,
    isBetaTestingEnabled,
  ]);

  return companyMembers;
}
