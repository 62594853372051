import { TilesContainer } from "@components/common/tiles-container";
import { IntegrationSkeletonTile } from "@components/common/skeleton-loading/integration-skeleton-tile";

/** Default number of skeleton tiles to be rendered */
const numberOfTiles = 6;

interface Props {
  /** Number of skeleton tiles to be rendered */
  numberOfSkeletonTiles?: number;
}

/**
 * Renders loading screen for workspace and project integrations page
 */
export function IntegrationSkeleton({
  numberOfSkeletonTiles = numberOfTiles,
}: Props): JSX.Element {
  return (
    <TilesContainer>
      {[...Array(numberOfSkeletonTiles)].map((_, index) => (
        <IntegrationSkeletonTile key={index} />
      ))}
    </TilesContainer>
  );
}
