import { APITypes } from "@stellar/api-logic";
import {
  RequiredFeatureProjectLevelName,
  RequiredFeaturesProjectLevel,
} from "@utils/feature-control/project/project-feature-control-types";

/**
 * Object that determines all different feature permission for users to get
 * access on the project level.
 */
export const requiredFeaturesProjectLevel: RequiredFeaturesProjectLevel<RequiredFeatureProjectLevelName> =
  {
    /** Whether the user can archive projects. */
    [RequiredFeatureProjectLevelName.canArchiveProject]: {
      projectSubscriptionRoles: [APITypes.EUserSubscriptionRole.projectArchive],
    },
  };
