import { isNumber } from "lodash";
import { Euler, Quaternion } from "three";
import { BaseCaptureTreeEntity, ClusterEntity } from "@custom-types/capture-tree-types";
import { getIdentityPose, getTopLevelClusterEntities } from "@utils/capture-tree-utils";
import { EventProps } from "@faro-lotv/foreign-observers";
import { assert, convertToDateString, generateGUID, GUID } from "@faro-lotv/foundation";
import { IQuat, IVec3 } from "@faro-lotv/ielement-types";
import {
  CaptureTreeEntityType,
  CaptureTreePointCloudType,
  CreateOrUpdateScanEntityParams,
  PreRegistrationEdge,
  RegistrationEdge,
  RegistrationEdgeType,
  RegistrationState,
  SlamRegistrationEdge,
  Transformation,
} from "@faro-lotv/service-wires";
import { ElsScanFileUploadTaskContext, UploadedFile } from "@custom-types/file-upload-types";
import { isValidFile } from "@hooks/file-upload-utils";
import { FILE_SIZE_MULTIPLIER, getFileExtension } from "@utils/file-utils";
import { ProjectApi } from "@api/project-api/project-api";
import { Edge, ReadLsDataV2Response, Scan } from "@api/stagingarea-api/stagingarea-api-types";
import { getScanByFilename } from "@api/stagingarea-api/stagingarea-api";
import { APITypes } from "@stellar/api-logic";
import { UUID } from "@stellar/api-logic/dist/api/core-api/api-types";

/**
 * Type for ref value to determine if we should auto-start the scan upload.
 * If the user adds or removes files, the value will be reset to `null` to abort the auto-start.
 */
export enum AutoStartUpload {
  /** We've sent the scan metadata files (LsDataV2) to the Staging Area API, and are waiting for the result. */
  waiting = "waiting",
  /** We've received the decoded scan metadata, and will auto-start the upload if all preconditions are met. */
  start = "start",
}

/**
 * List of allowed extensions for importing scan data.
 * "index-v2" & co don't have an extension, so we need to allow all extensions and filter later.
 */
export const ALLOWED_EXTENSIONS_ALL: string[] = [];

/** List of allowed extensions for the scan upload. */
export const ALLOWED_EXTENSIONS_GLS: string[] = ["gls"];

/** Maximum file size of each scan file: 20 GB. Arbitrary choice, but big enough for any Faro scan. */
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const MAX_FILE_SIZE_IN_MB = 20 * FILE_SIZE_MULTIPLIER;

/** Els cluster name prefix */
const ELS_CLUSTER_NAME_PREFIX = "Blink Scans_";

/** Regular expression to validate the name of an ELS cluster. It should have the format "ELS Scans_{mm/dd/yyyy}" */
const ELS_CLUSTER_NAME_REGEX = new RegExp(
  `^${ELS_CLUSTER_NAME_PREFIX}\\d{2}\\/\\d{2}\\/\\d{4}$`
);

/** Regular expression to extract the UUID (usually UUIDv4) from "UUID.gls" or e.g. "UUID (2).gls". */
const CONTAINS_UUID_GLS_REGEX = /^.*([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}).*\.gls$/i;

/** @returns True if it's a *.gls file (ELS/Blink scan). */
export function isGLS(fileName: string): boolean {
  return fileName.toLowerCase().endsWith(".gls");
}

/** Extract the scan UUID from LsDataV2 or from the filename. */
export function getGlsUuid(fileName: string, lsDataV2: ReadLsDataV2Response | null): string | undefined {
  return getScanByFilename(fileName, lsDataV2)?.uuid ||
    fileName.match(CONTAINS_UUID_GLS_REGEX)?.[1]?.toLowerCase();
}

/** @returns True if the given GLS file may be uploaded. */
export function isValidGlsFile(file: File, lsDataV2: ReadLsDataV2Response | null): boolean {
  const isValid = isValidFile({
    file,
    allowedExtensions: ALLOWED_EXTENSIONS_GLS,
    maxFileSize: MAX_FILE_SIZE_IN_MB,
  }).isValid;

  // We only allow to upload scans whose UUID (as set by the scanner) is known.
  // Otherwise we can't check for duplicates later.
  return isValid && !!getGlsUuid(file.name, lsDataV2);
}

/** @returns The set of files (scans) whose UUIDs are contained in `uploadedIdsMap`. */
export function getScansAlreadyUploaded(
  files: File[],
  lsDataV2: ReadLsDataV2Response | null,
  uploadedIdsMap: { [key: APITypes.UUID]: boolean }
): Set<File> {
  const filesFound = files.filter((file) => {
    const uuid = getGlsUuid(file.name, lsDataV2);
    return uuid && uploadedIdsMap[uuid];
  });
  return new Set(filesFound);
}

/** @returns True if it's the "index-v2" file from the LsDataV2 format. */
export function isIndexV2(fileName: string): boolean {
  return fileName.toLowerCase() === "index-v2";
}

/** @returns True if it's a file "ls-data/objects/[0-9a-f]" from the LsDataV2 format. */
export function isLsDataObject(file: File): boolean {
  // According to the FW team, there can be max. 16 files "ls-data/objects/[0-9a-f]",
  // and no multi-digit filenames.
  // If we have the relative path available, use it to better filter out irrelevant files,
  // since file.name = [0-9a-f] is quite generic.
  const isLsDataObject = file.webkitRelativePath ?
    file.webkitRelativePath.toLowerCase().includes("ls-data/objects/") :
    undefined;

  return isLsDataObject !== false && (/^[0-9a-f]$/i).test(file.name);
}

interface OtherFilesInfo {
  [extLower: string]: {
    count: number;
    size: number;
  }
}

interface FilesInfoEventProps extends EventProps {
  filesGLS: number;
  filesGLSSize: number;
  filesLsDataV2: number;
  filesLsDataV2Size: number;
  filesOther: OtherFilesInfo;
}

/** @returns Info about files being added (dragged or selected), for tracking. */
export function filesInfoForTracking(files: File[]): FilesInfoEventProps {
  const glsFiles = files.filter((file) => isGLS(file.name));
  const lsDataV2Files = files.filter((file) => !isGLS(file.name) && (isIndexV2(file.name) || isLsDataObject(file)));
  const otherFiles = files.filter((file) => !isGLS(file.name) && !isIndexV2(file.name) && !isLsDataObject(file));

  const otherFilesMap: OtherFilesInfo = {};
  for (const file of otherFiles) {
    const extLower = getFileExtension(file.name);
    otherFilesMap[extLower] = otherFilesMap[extLower] || { count: 0, size: 0 };
    otherFilesMap[extLower].count++;
    otherFilesMap[extLower].size += file.size;
  }

  return {
    filesGLS: glsFiles.length,
    filesGLSSize: glsFiles.reduce((sum, file) => sum + file.size, 0),
    filesLsDataV2: lsDataV2Files.length,
    filesLsDataV2Size: lsDataV2Files.reduce((sum, file) => sum + file.size, 0),
    filesOther: otherFilesMap,
  };
}

/** @returns Info about the data in the LsDataV2 package, for tracking. */
export function lsDataV2InfoForTracking(lsDataV2: ReadLsDataV2Response | null): {
  hasLsDataV2: boolean;
  lsScans: number;
  lsClusters: number;
  lsEdges: number;
} {
  return {
    hasLsDataV2: !!lsDataV2,
    lsScans: lsDataV2?.scans?.length ?? 0,
    lsClusters: lsDataV2?.clusters?.length ?? 0,
    lsEdges: lsDataV2?.edges?.length ?? 0,
  };
}

/**
 * @param name string to validate
 * @returns Whether the passed string is a valid name for a ELS cluster
 */
export function isValidElsClusterName(name: string): boolean {
  return ELS_CLUSTER_NAME_REGEX.test(name);
}

/**
 * @returns a valid ELS cluster name of format "ELS Scans_{mm/dd/yyyy}"
 */
export function getElsClusterName(): string {
  const date = convertToDateString(new Date().toISOString(), "en-US");
  return `${ELS_CLUSTER_NAME_PREFIX}${date}`;
}

/**
 * @param entities The capture tree entities
 * @returns The first found "ELS" cluster entity or undefined if not found
 * A cluster is considered the "ELS" cluster if:
 * - its a top-level cluster and
 * - it has a valid name for an ELS cluster
 */
export function getElsClusterEntity<T extends BaseCaptureTreeEntity>(
  entities: T[]
): ClusterEntity<T> | undefined {
  const topLevelClusters = getTopLevelClusterEntities(entities);
  return topLevelClusters.find((cluster) =>
    isValidElsClusterName(cluster.name)
  );
}

interface GetScanEntitiesParamsProps {
  /** List of successfully uploaded scans to add */
  uploadedScans: UploadedFile[];

  /** ID of the revision cluster entity where the scans will be added */
  revisionClusterEntityId: string;

  /** Scan metadata, to map from filename (*.gls) to scan metadata from LsDataV2. */
  lsDataV2: ReadLsDataV2Response | null;
}

/** Stricter version of CreateOrUpdateScanEntityParams, so we need less type assertions. */
interface CreateOrUpdateScanEntityParamsWithPC extends CreateOrUpdateScanEntityParams {
  id: GUID;
  pointClouds: NonNullable<Required<CreateOrUpdateScanEntityParams["pointClouds"]>>;
}

/** @returns 3D translation and rotation for Capture Tree, converted from the LsDataV2 values. */
export function lsToCaptureTreeTrafo([x, y, z]: number[], rotationAngles: number[]): Transformation {
  return {
    pos: { x, y, z },
    rot: lsToCaptureTreeRotation(rotationAngles),
  };
}

/** @returns 3D translation for Capture Tree, converted from the LsDataV2 values. */
export function lsToCaptureTreeTranslation([x, y, z]: number[]): IVec3 {
  return { x, y, z };
}

/** @returns 3D rotation for Capture Tree, converted from the LsDataV2 values. */
export function lsToCaptureTreeRotation([rx, ry, rz]: number[]): IQuat {
  // LsDataV2 does not yet properly specify its rotations. Our code here is at least consistent with the
  // Stream app, and produces correct results for usual cases (rotation around Z axis; XY almost 0°).
  // FW-1617, https://faro01.atlassian.net/wiki/spaces/FW/pages/4316332063/Unified+representation+of+scan+scanner+poses
  const euler = new Euler(rx, ry, rz, "XYZ");
  const quat = new Quaternion().setFromEuler(euler);
  return {
    x: quat.x,
    y: quat.y,
    z: quat.z,
    w: quat.w,
  };
}

/**
 * @returns An array of params required to create capture tree scan entities from the passed uploaded scans
 */
export function getCreateScanEntitiesParams({
  uploadedScans,
  revisionClusterEntityId,
  lsDataV2,
}: GetScanEntitiesParamsProps): CreateOrUpdateScanEntityParamsWithPC[] {
  return uploadedScans.map((uploadedScan) => {
    // `scan` will be undefined in these cases, so we should allow it.
    //   - The user uploaded only the GLS files, without LsDataV2.
    //   - The user added an extra GLS file which is not in LsDataV2.
    // Knowing the scan UUID is still mandatory; it must be found in the *.gls file name then.
    const scan: Scan | undefined = getScanByFilename(uploadedScan.fileName, lsDataV2);

    const translation = scan?.trafo?.translation || [];
    const rotationAngles = scan?.trafo?.rotationAngles || [];
    // Getter getIdentityPose() creates a new object, so we can safely assign to .pos and .rot below.
    const pose = getIdentityPose();
    if (isNumber(translation[0]) && isNumber(translation[1]) && isNumber(translation[2])) {
      pose.pos = lsToCaptureTreeTranslation(translation as number[]);
    }
    if (isNumber(rotationAngles[0]) && isNumber(rotationAngles[1]) && isNumber(rotationAngles[2])) {
      pose.rot = lsToCaptureTreeRotation(rotationAngles as number[]);
    }

    // Use the UUID from LsDataV2 if available, otherwise extract it from the filename.
    // Knowing the UUID is important to detect later if a scan was already uploaded, e.g. in case that the user
    // tries to upload the same project again with some additional scans.
    const externalId = scan?.uuid || getGlsUuid(uploadedScan.fileName, null);
    if (!externalId) {
      // Unexpected code path, because import-data > isConfirmDisabled > isValidGlsFile verifies that each scan has a UUID.
      throw new Error(`Logic error: We should only accept scans with a UUID: ${uploadedScan.fileName}`);
    }

    return {
      // We need to generate a GUID client-side so we can reference the scan when we create the edges.
      id: generateGUID(),
      parentId: revisionClusterEntityId,
      type: CaptureTreeEntityType.elsScan,
      name: scan?.name || uploadedScan.fileName,
      pose,
      pointClouds: [
        {
          externalId,
          type: CaptureTreePointCloudType.elsRaw,
          uri: uploadedScan.downloadUrl,
          md5Hash: uploadedScan.md5,
          fileSize: uploadedScan.fileSize,
          fileName: uploadedScan.fileName,
        },
      ],
    };
  });
}

/**
 * Converts an edge from the LsDataV2 format to the Capture Tree format.
 * https://faro01.atlassian.net/wiki/spaces/REG/pages/4016472095/Edge+Registration+JSON+Data+structure
 * https://bitbucket.org/farosw/flow/src/master/src/services/sphere2/client/CaptureTreeRevision.ts
 *
 * @param lsEdge LsDataV2 edge to convert.
 * @param mapScanUuidToGuid Map from LsDataV2 scan UUID to Capture Tree scan GUID ("id" attribute).
 * @returns RegistrationEdge, or undefined if we don't support the edge type.
 */
export function lsToCaptureTreeEdge(
  lsEdge: Edge,
  mapScanUuidToGuid: Map<UUID, GUID>
): Partial<PreRegistrationEdge | SlamRegistrationEdge> | undefined {
  const lsType = lsEdge.constraint.type;
  if (lsType !== "SLAMRegistrationPoseConstraintObject" && lsType !== "TVBRegistrationPoseConstraintObject") {
    // Unsupported edge type.
    return undefined;
  }

  const { rotationAngles, translation } = lsEdge.constraint?.data?.trafo || {};
  const hasTrafo = !!rotationAngles && !!translation &&
    isNumber(rotationAngles[0]) && isNumber(rotationAngles[1]) && isNumber(rotationAngles[2]) &&
    isNumber(translation[0]) && isNumber(translation[1]) && isNumber(translation[2]);
  const fscore = lsEdge.constraint.data.quality;

  const sourceId = mapScanUuidToGuid.get(lsEdge.sourceUuid);
  const targetId = mapScanUuidToGuid.get(lsEdge.targetUuid);
  assert(sourceId && targetId, "Missing source or target ID for edge");

  const edge: Partial<PreRegistrationEdge | SlamRegistrationEdge> = {
    // SMETA-1486: Generating the GUID seems required for edges, otherwise we get this confusing error:
    // "invalid_registration_edge" / "Source and target must be the same as the original edge."
    id: generateGUID(),
    type: lsType === "SLAMRegistrationPoseConstraintObject" ? RegistrationEdgeType.slam : RegistrationEdgeType.preReg,
    sourceId,
    targetId,
    data: hasTrafo ? {
      jsonRevision: 3,
      transformation: lsToCaptureTreeTrafo(translation as number[], rotationAngles as number[]),
      metrics: isNumber(fscore) ? {
        fscore,
      } : undefined,
    } : undefined,
  };
  return edge;
}

/**
 * Handles the logic after the upload of ELS scan succeeded:
 * - Adds (creates) successful uploads to the specified cluster of the project revision.
 * - Updates revision to "registered" status and merges it to the main revision.
 */
export async function addScansToRevisionAndMergeToMainHelper(
  successfulUploads: UploadedFile[],
  projectApiClient: ProjectApi,
  context: ElsScanFileUploadTaskContext
): Promise<void> {
  const scanEntitiesParams = getCreateScanEntitiesParams({
    uploadedScans: successfulUploads,
    revisionClusterEntityId: context.revisionClusterEntityId,
    lsDataV2: context.lsDataV2,
  });

  await projectApiClient.createOrUpdateScanEntitiesForRegistrationRevision({
    registrationRevisionId: context.registrationRevisionId,
    requestBody: scanEntitiesParams,
  });

  const mapScanUuidToGuid = new Map<UUID, GUID>();
  for (const scan of scanEntitiesParams) {
    assert(scan.pointClouds[0].externalId);
    mapScanUuidToGuid.set(scan.pointClouds[0].externalId, scan.id);
  }

  // For now, create only those edges that have both source and target in the set of new scans.
  const edgesToCreate = (context.lsDataV2?.edges || []).filter((lsEdge) =>
    lsEdge.sourceUuid && lsEdge.targetUuid &&
    mapScanUuidToGuid.has(lsEdge.sourceUuid) && mapScanUuidToGuid.has(lsEdge.targetUuid)
  )
    .map((lsEdge) => lsToCaptureTreeEdge(lsEdge, mapScanUuidToGuid))
    .filter((edge) => !!edge);

  await projectApiClient.createOrUpdateRegistrationEdges({
    registrationRevisionId: context.registrationRevisionId,
    // TODO(TF-1873): The client has some mandatory parameters that are optional and better generated by the backend
    //                (e.g. createdAt). The cast should not be necessary.
    requestBody: edgesToCreate as RegistrationEdge[],
  });

  await projectApiClient.updateRegistrationRevision({
    registrationRevisionId: context.registrationRevisionId,
    state: RegistrationState.registered,
  });

  await projectApiClient.applyRegistrationRevisionToMain(
    context.registrationRevisionId
  );
}
