import { useAppSelector } from "@store/store-helper";
import {
  selectedProjectContextSelector,
  selectedProjectSelector,
} from "@store/projects/projects-selector";
import { useMemo } from "react";
import { RequiredFeatureProjectLevelName } from "@utils/feature-control/project/project-feature-control-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { hasUserValidFeatureProjectLevel } from "@utils/feature-control/project/project-feature-control";

interface Props {
  /** The internal name used in the UI to determine certain feature */
  roleName: RequiredFeatureProjectLevelName;

  /** The available features of the project. */
  featuresAvailable: SphereDashboardAPITypes.IFeature[];
}

export type ReturnProps = {
  [key in RequiredFeatureProjectLevelName]: boolean;
};

/** Checks whether a user has permission for an action on project level or not */
export function useHasValidFeatureProjectLevel({
  featuresAvailable = undefined,
}: Partial<Pick<Props, "featuresAvailable">> = {}): ReturnProps {
  const selectedProjectInStore = useAppSelector(selectedProjectSelector);
  const projectContext = useAppSelector(selectedProjectContextSelector);

  /** If user provided a featuresAvailable, then use that. Otherwise, use from selectedProject */
  const availableFeatures = useMemo(
    () =>
      featuresAvailable ??
      selectedProjectInStore?.featuresAvailable ??
      projectContext?.featuresAvailable,
    [
      featuresAvailable,
      projectContext?.featuresAvailable,
      selectedProjectInStore?.featuresAvailable,
    ]
  );

  const canArchiveProject = useMemo(
    () =>
      hasUserValidFeatureProjectLevel({
        roleName: RequiredFeatureProjectLevelName.canArchiveProject,
        featuresAvailable: availableFeatures,
      }),
    [availableFeatures]
  );

  return {
    canArchiveProject,
  };
}
