import { useCoreApiClient } from "@api/use-core-api-client";
import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { FaroButtonContained } from "@components/common/faro-button-contained";
import { SphereAvatar } from "@components/header/sphere-avatar";
import { AutoCompleteMessage } from "@components/common/faro-text-field/faro-text-field-message";
import { LabelWithHelp } from "@components/common/label-with-help";
import { MembersAutocomplete } from "@components/common/members-autocomplete/members-autocomplete";
import { createMemberOption } from "@components/common/members-autocomplete/members-autocomplete-utils";
import { AutoCompleteMemberOption } from "@components/common/members-autocomplete/members-autocomplete-types";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useCompanyMembers } from "@hooks/use-company-members";
import { useToast } from "@hooks/use-toast";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useAppParams } from "@router/router-helper";
import {
  TEAM_DISPLAY_NAME,
  TEAMS_DISPLAY_NAME,
} from "@src/constants/team-constants";
import { useAppDispatch } from "@store/store-helper";
import { addMembersToTeam, createTeam } from "@store/teams/teams-slice-thunk";
import { STACK_ERROR_SX } from "@styles/common-styles";
import { nounPluralize } from "@utils/data-display";
import { capitalizeFirstLetter } from "@utils/string-utils";
import { TeamEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useMemo, useState } from "react";
import {
  APITypes,
  CoreAPITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";
import {
  isNewEmailValid,
  MAX_INVITED_MEMBERS,
  shouldDisableSendInvite,
  validateInputChange,
  validateMembers,
} from "@pages/members/teams/teams-utils";
import { FaroButton } from "@components/common/faro-button";
import { isValidTeamName } from "@utils/team-utils";
import { TextField } from "@faro-lotv/flat-ui";
import Team from "@assets/icons/new/team.svg?react";
import { sphereColors } from "@styles/common-colors";
import { SelectCompanyRole } from "@components/role/select-company-role";
import { SelectGroup } from "src/pages/members/select-group";
import { updateMemberRoleInWorkspace } from "@store/members/members-slice-thunk";
import { setOne } from "@store/members/members-slice";
import { convertToTeamMemberTypes } from "@store/members/members-slice-utils";

interface Props {
  /** Should show the contained button or not */
  shouldUseContainedButton?: boolean;
}

/** The max number of characters for the input name */
const MAX_NAME_CHARACTERS = 50;

/** The max number of characters for the input description */
const MAX_DESCRIPTION_CHARACTERS = 500;

/** Renders the create button and dialog to create team */
export function CreateTeam({
  shouldUseContainedButton = true,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { showToast } = useToast();
  const { handleErrorWithToast } = useErrorContext();
  const coreApiClient = useCoreApiClient();
  const companyMembers = useCompanyMembers();
  const { companyId } = useAppParams();
  const { trackEvent } = useTrackEvent();

  const [teamName, setTeamName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<AutoCompleteMessage | undefined>();
  const [selectedRole, setSelectedRole] = useState<
    SphereDashboardAPITypes.IAssignmentCompanyRole | undefined
  >(undefined);
  const [selectedGroupId, setSelectedGroupId] = useState<string>("");

  /** Flag whether the input field in the autocomplete component has a valid email */
  const [isMemberInputValid, setIsMemberInputValid] = useState<boolean>(false);

  /** Stores the ids of the users to be assigned as group managers */
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);

  /** Determines if the team name is valid */
  const isValidName = isValidTeamName(teamName);

  /** Selected roles that needs to show group list when inviting them  */
  const shouldShowGroupList =
    selectedRole === CoreAPITypes.EUserCompanyRole.companyManager ||
    selectedRole === CoreAPITypes.EUserCompanyRole.projectManager;

  /**
   * Whether the create button should be disabled or not.
   * The button should be disabled if:
   * - The team name is empty
   * - The team name isn't valid
   * - The number of selected members exceeds the maximum allowed for invitation.
   *  Note: Selecting members is optional. However, if members are selected, their count must be valid
   *  (i.e., within the allowed range).
   */
  const isCreateDisabled =
    (selectedMemberIds.length > 0 &&
      shouldDisableSendInvite(selectedMemberIds, isMemberInputValid)) ||
    !isValidName ||
    teamName.trim().length < 1 ||
    teamName.trim().length > MAX_NAME_CHARACTERS ||
    description.trim().length > MAX_DESCRIPTION_CHARACTERS ||
    ((selectedRole === CoreAPITypes.EUserCompanyRole.companyManager ||
      selectedRole === CoreAPITypes.EUserCompanyRole.projectManager) &&
      !selectedGroupId.length);

  /** Stores the members of the company in the format required by the autocomplete */
  const memberOptions: AutoCompleteMemberOption[] = useMemo(() => {
    return companyMembers.map((member) => createMemberOption({ member }));
  }, [companyMembers]);

  /** Open the dialog. */
  // eslint-disable-next-line @typescript-eslint/require-await -- Please review lint error
  async function onOpenDialog(): Promise<void> {
    trackEvent({ name: TeamEvents.startCreateNewTeam });
    setIsDialogOpen(true);
  }

  /** Resets the dialog content, and close the dialog. */
  // eslint-disable-next-line @typescript-eslint/require-await -- Please review lint error
  async function onCloseDialog(): Promise<void> {
    setTeamName("");
    setDescription("");
    setSelectedGroupId("");
    setSelectedRole(undefined);
    setSelectedMemberIds([]);
    setMessage(undefined);
    setIsDialogOpen(false);
  }

  /** Triggered when the create button is clicked, creates the group and closes the dialog */
  async function handleConfirm(): Promise<void> {
    if (!companyId) {
      throw new Error("companyId is required");
    }

    setIsLoading(true);

    trackEvent({
      name: TeamEvents.createNewTeam,
      props: { numberOfMembers: selectedMemberIds.length },
    });

    try {
      // Create Team
      const team = await dispatch(
        createTeam({
          coreApiClient,
          companyId,
          teamName: teamName.trim(),
          description: description.trim(),
        })
      ).unwrap();

      const addNewTeamMemberStore = convertToTeamMemberTypes(
        team,
        selectedRole
      );

      dispatch(setOne(addNewTeamMemberStore));

      await dispatch(
        updateMemberRoleInWorkspace({
          coreApiClient,
          companyId,
          role: selectedRole as SphereDashboardAPITypes.UpdateCompanyMemberRole,
          identity: team.identity,
        })
      );

      setTeamName(teamName.trim());

      /** Team creation success message */
      const responseMessage = `${capitalizeFirstLetter(
        TEAM_DISPLAY_NAME
      )} created successfully`;

      /** Check if there are selected members to add; if so, proceed to add them, otherwise show a success message. */
      if (selectedMemberIds.length > 0) {
        await handleAddMembers(team.id, responseMessage);
      } else {
        showToast({
          type: "success",
          message: responseMessage,
        });
      }

      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      onCloseDialog();
    } finally {
      setIsLoading(false);
    }
  }

  /**
   * Add the members to the team
   * @param teamId The id of the team
   * @param responseMessage The message to be displayed after adding the members
   * @returns Promise<void>
   */
  async function handleAddMembers(
    teamId: string,
    responseMessage: string
  ): Promise<void> {
    if (!companyId) {
      throw new Error("companyId is required");
    }
    try {
      // Add members
      const response = await dispatch(
        addMembersToTeam({
          coreApiClient,
          companyId,
          teamId,
          members: selectedMemberIds,
        })
      ).unwrap();

      // Show response messages after adding members
      // Errors of creating team is handled by error slice
      const additionalMessages: string[] = [];

      if (response.errorData) {
        response.errorData.forEach((error: { message: string }) => {
          additionalMessages.push(error.message);
        });
      }

      // The status of the response
      // success: When all the invites go fine
      // warning: When some of the invites goes fine and some are failed
      // error: When all the invites are failed
      showToast({
        type: response.status,
        message: responseMessage,
        description: additionalMessages.map((message, index) => (
          <li key={index}>
            <var>{message}</var>
          </li>
        )),
      });
    } catch (error) {
      handleErrorWithToast({
        id: `inviteMemberToTeam-${Date.now().toString()}`,
        title: `${responseMessage}. But couldn't invite the ${nounPluralize({
          shouldShowCounter: false,
          counter: selectedMemberIds.length,
          word: "member",
        })}`,
        error,
      });
    }
  }

  /** Stores the ids of the members of the company */
  const memberIds = useMemo(
    () =>
      companyMembers.map(
        (member) => member.id?.toLowerCase() ?? member.email.toLowerCase()
      ),
    [companyMembers]
  );

  function handleMemberSelect(members: APITypes.UserIdentity[]): void {
    const { message, isValid } = validateMembers(
      members,
      TEAM_DISPLAY_NAME,
      MAX_INVITED_MEMBERS,
      memberIds
    );

    setMessage(message);
    setIsMemberInputValid(isValid);
    setSelectedMemberIds(members);
  }

  /**
   * Function to be called when the input value changes
   * It sets the valid emails to the addedEmails state and removes them from the input value
   */
  function onInputChange(value: string): void {
    const { message, isValid } = validateInputChange(
      memberOptions,
      companyMembers,
      value,
      TEAM_DISPLAY_NAME
    );

    setMessage(message);
    setIsMemberInputValid(isValid);
  }

  /** Reset the states for the groups */
  function resetGroupsState(): void {
    setSelectedRole(undefined);
    setSelectedGroupId("");
  }

  return (
    <>
      {shouldUseContainedButton ? (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        <FaroButtonContained onClick={onOpenDialog}>
          New {TEAM_DISPLAY_NAME}
        </FaroButtonContained>
      ) : (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        <FaroButton onClick={onOpenDialog}> New {TEAM_DISPLAY_NAME}</FaroButton>
      )}

      {/* Create project dialog */}
      <FaroDialog
        title={`Created new ${TEAM_DISPLAY_NAME}`}
        open={isDialogOpen}
        confirmText="Create"
        isConfirmDisabled={isCreateDisabled}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        onConfirm={handleConfirm}
        isConfirmLoading={isLoading}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        onClose={onCloseDialog}
      >
        <Grid maxWidth="100%" width="70vw" paddingX={"2px"}>
          <Grid container alignItems="center">
            <Grid item xs>
              <Box
                borderBottom={`1px solid ${sphereColors.gray200}`}
                width="100%"
              />
            </Grid>

            <Grid item>
              <Box mx={2}>
                <SphereAvatar
                  icon={<Team width={"40px"} height={"40px"} />}
                  size="x-large"
                  backgroundColor={sphereColors.gray700}
                />
              </Box>
            </Grid>

            <Grid item xs>
              <Box
                borderBottom={`1px solid ${sphereColors.gray200}`}
                width="100%"
              />
            </Grid>
          </Grid>

          <Typography marginY={"32px"} fontSize={"14px"}>
            {capitalizeFirstLetter(TEAMS_DISPLAY_NAME)} allow you to group
            individual members and assign roles to the entire group at once,
            saving time compared to assigning roles to each user individually.
          </Typography>

          {/* Team name */}
          <Stack sx={STACK_ERROR_SX}>
            <LabelWithHelp
              title={`${capitalizeFirstLetter(TEAM_DISPLAY_NAME)} name`}
              isRequired
            />
            <TextField
              maxCharacterCount={MAX_NAME_CHARACTERS}
              shouldShowCounter={true}
              onTextChanged={(name) => {
                setTeamName(name);
              }}
              text={teamName}
              fullWidth
              placeholder={`Enter the ${TEAM_DISPLAY_NAME} name...`}
              sx={{
                height: "38px",
                fontSize: "14px",
              }}
              autoFocus
              error={
                isValidName
                  ? ""
                  : `${capitalizeFirstLetter(
                      TEAM_DISPLAY_NAME
                    )} name shouldn't contain comma(,) or semicolon(;)`
              }
            />
          </Stack>

          <Stack sx={STACK_ERROR_SX}>
            <SelectCompanyRole
              selectedRole={
                selectedRole as SphereDashboardAPITypes.IAssignmentCompanyRole
              }
              onChange={setSelectedRole}
              onResetSelection={resetGroupsState}
              isTableCell={false}
              placeholder="Assign a workspace role to your team if needed"
              isRequired={false}
            />
          </Stack>

          {shouldShowGroupList && (
            <Stack sx={STACK_ERROR_SX}>
              <SelectGroup
                selectedGroupId={selectedGroupId}
                onChange={setSelectedGroupId}
              />
            </Stack>
          )}

          {/* Team members */}
          <Stack sx={STACK_ERROR_SX}>
            <MembersAutocomplete
              options={memberOptions}
              handleChange={handleMemberSelect}
              onInputChange={onInputChange}
              placeHolder="Enter member name or email"
              message={message}
              validateNewOption={(email) =>
                isNewEmailValid(email, companyMembers)
              }
              // If one of the members is preselected, they should be added to the group by default.
              initialValue={memberOptions.filter(
                (memberOption) => memberOption.isPreselected
              )}
              labelTitle="Members"
              isRequired={false}
            />
          </Stack>

          {/* Team description */}
          <LabelWithHelp title="Description" />
          <TextField
            maxCharacterCount={MAX_DESCRIPTION_CHARACTERS}
            shouldShowCounter={true}
            onTextChanged={(description) => {
              setDescription(description);
            }}
            text={description}
            fullWidth
            placeholder={`Enter the ${TEAM_DISPLAY_NAME} description...`}
            multiline={true}
            rows={4}
            sx={{ fontSize: "14px" }}
          />
        </Grid>
      </FaroDialog>
    </>
  );
}
