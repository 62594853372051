import ComingSoonIcon from "@assets/icons/new/coming-soon_60px.svg?react";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import { useAppSelector } from "@store/store-helper";
import { isAlphaTestingEnabledSelector } from "@store/app/app-selector";
import { IntegrationSection } from "@pages/integrations/integration-section";
import { useWorkspaceIntegrations } from "@pages/integrations/use-workspace-integrations";
import { useState } from "react";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { IntegrationDialog } from "@pages/integrations/integrations-dialog/integration-dialog";
import { NoIntegration } from "@pages/integrations/no-integration";
import { IntegrationSkeleton } from "@components/common/skeleton-loading/integration-skeleton";

/** Renders the integrations page in workspace level */
export function WorkspaceIntegrations(): JSX.Element {
  const isAlphaTestingEnabled = useAppSelector(isAlphaTestingEnabledSelector);
  const [selectedIntegrationId, setSelectedIntegrationId] =
    useState<SphereDashboardAPITypes.IntegrationId>(
      SphereDashboardAPITypes.IntegrationId.autodesk
    );

  const {
    allIntegrations,
    onEnableIntegration,
    selectedDialog,
    setSelectedDialog,
    onSuccessDialogConfirm,
    onInProgressDialogCancel,
    onDisconnectDialogConfirm,
    onDisconnectDialogCancel,
    isLoadingIntegrations,
  } = useWorkspaceIntegrations(selectedIntegrationId);

  if (!isAlphaTestingEnabled) {
    return (
      <EmptyPage
        title="Coming soon"
        subtitle="This page is still under development and will be available soon"
        icon={ComingSoonIcon}
      />
    );
  }

  if (isLoadingIntegrations) {
    return <IntegrationSkeleton />;
  }

  return (
    <>
      {allIntegrations.enabledIntegrationIds.length === 0 ? (
        <NoIntegration subject="workspace" />
      ) : (
        <IntegrationSection
          title="Authorized apps"
          integrations={allIntegrations.enabledIntegrationIds}
          actionButtonText="DisconnectFromWorkspace"
          onIntegrationAction={(integrationId) => {
            setSelectedIntegrationId(integrationId);
            setSelectedDialog("disconnect");
          }}
        />
      )}

      {allIntegrations.availableIntegrationIds.length !== 0 && (
        <IntegrationSection
          title="Available apps"
          integrations={allIntegrations.availableIntegrationIds}
          actionButtonText="ConnectToWorkspace"
          onIntegrationAction={(integrationId) => {
            setSelectedIntegrationId(integrationId);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
            onEnableIntegration(integrationId);
          }}
        />
      )}

      {selectedDialog && (
        <IntegrationDialog
          selectedIntegrationId={selectedIntegrationId}
          selectedDialog={selectedDialog}
          onSuccessDialogConfirm={onSuccessDialogConfirm}
          onInProgressDialogCancel={onInProgressDialogCancel}
          onDisconnectDialogConfirm={onDisconnectDialogConfirm}
          onDisconnectDialogCancel={onDisconnectDialogCancel}
        />
      )}
    </>
  );
}
