import {
  BaseCaptureTreeEntity,
  ClusterEntity,
  RootEntity,
  SCAN_ENTITY_TYPES,
  ScanEntity,
} from "@custom-types/capture-tree-types";
import { CaptureTreeEntityType, Transformation } from "@faro-lotv/service-wires";

/**
 * @returns A new instance of an identity transformation for Capture Tree / ProjectAPI.
 *          This means you can safely modify any property of the returned object.
 */
export function getIdentityPose(): Transformation {
  return {
    pos: { x: 0, y: 0, z: 0 },
    rot: { x: 0, y: 0, z: 0, w: 1 },
  };
}

/**
 * @param entity The capture tree entity to check.
 * @returns Whether the entity is a root.
 */
export function isRootEntity<T extends BaseCaptureTreeEntity>(
  entity: T
): entity is RootEntity<T> {
  return entity.type === CaptureTreeEntityType.root && entity.parentId === null;
}

/**
 * @param entity The capture tree entity to check.
 * @returns Whether the entity is a cluster.
 */
export function isClusterEntity<T extends BaseCaptureTreeEntity>(
  entity: T
): entity is ClusterEntity<T> {
  return entity.type === CaptureTreeEntityType.cluster;
}

/**
 * @param entity The capture tree entity to check.
 * @returns Whether the entity is a scan.
 */
export function isScanEntity<T extends BaseCaptureTreeEntity>(
  entity: T
): entity is ScanEntity<T> {
  return Object.values<CaptureTreeEntityType>(SCAN_ENTITY_TYPES).includes(
    entity.type
  );
}

/**
 * @param entities The capture tree entities
 * @returns The root entity or undefined if not found
 */
export function getRootEntity<T extends BaseCaptureTreeEntity>(
  entities: T[]
): RootEntity<T> | undefined {
  return entities.find(isRootEntity);
}

/**
 * @param entities The capture tree entities
 * @returns The cluster entities
 */
export function getClusterEntities<T extends BaseCaptureTreeEntity>(
  entities: T[]
): ClusterEntity<T>[] {
  return entities.filter(isClusterEntity);
}

/**
 * @param entities The capture tree entities
 * @returns The top-level cluster entities
 * A top-level cluster entity has the root entity as direct parent
 */
export function getTopLevelClusterEntities<T extends BaseCaptureTreeEntity>(
  entities: T[]
): ClusterEntity<T>[] {
  const root = getRootEntity(entities);
  if (!root) {
    return [];
  }

  const clusters = getClusterEntities(entities);
  return clusters.filter((cluster) => cluster.parentId === root.id);
}
